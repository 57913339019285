import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { ThemeProvider } from "styled-components"

import theme from "./styles/Theme"
import GlobalStyle from "./styles/Golbal"

import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import FooterAlt from "./Footer/FooterAlt"

const getData = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Layout = ({ children, location }) => {
  const data = useStaticQuery(getData)
  return (
    <>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <GlobalStyle />
          <Header
            location={location}
            siteTitle={data.site.siteMetadata.title}
          />
          <div>
            <main>{children}</main>
          </div>
          {location === "/contact" || location === "/contact/" ? (
            <FooterAlt />
          ) : (
            <Footer />
          )}
        </React.Fragment>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
