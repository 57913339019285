import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const ClientLogoStyled = styled.div`
  align-self: center;
  width: calc(33.3333% - 3rem);
  margin: 1rem 1.5rem;

  @media (min-width: 768px) {
    width: calc(25% - 4rem);
    margin: 2rem;
  }

  @media (min-width: 1025px) {
    width: calc(20% - 4rem);
    margin: 2rem;
  }

  .logo-container {
    width: 100%;
  }
`

const ClientLogo = props => {
  const { logo } = props
  const displyLogo =
    logo?.acf?._swb_client_logo?.localFile !== null ? true : false
  const logoDisplay =
    logo?.acf?._swb_client_url !== "" && displyLogo ? (
      <a
        className="logo-container"
        target="_blank"
        href={logo.acf._swb_client_url}
      >
        {logo?.acf?._swb_client_logo?.localFile?.childImageSharp?.fluid && (
          <Img
            fluid={
              logo?.acf?._swb_client_logo?.localFile?.childImageSharp?.fluid
            }
            alt={logo.title}
          />
        )}
      </a>
    ) : (
      <div className="logo-container">
        {logo?.acf?._swb_client_logo?.localFile?.childImageSharp?.fluid && (
          <Img
            fluid={
              logo?.acf?._swb_client_logo?.localFile?.childImageSharp?.fluid
            }
            alt={logo.title}
          />
        )}
      </div>
    )

  return <ClientLogoStyled>{logoDisplay}</ClientLogoStyled>
}

export default ClientLogo
