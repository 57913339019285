import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { colors, fonts, fontSizer } from "../../../Utilities"

const MainNavSubMenuItemStyled = styled.li`
  ${fontSizer(1.2, 1.3, 76.8, 150, 1.4)}
  width: 100%;
  text-align: left;

  a.sub-menu-link {
    display: block;
    width: 100%;
    padding: 1rem 0.5rem;
    background: ${props =>
      props.isCurrentSub ? props.theme.colorSec : "transparent"};
    color: ${props => (props.isCurrentSub ? colors.black : colors.white)};
    font-family: ${fonts.fontPrimary};
    font-size: 1em;
    font-weight: bold;

    &:hover {
      color: ${colors.black};
      background: ${props =>
        props.isCurrentSub
          ? props.theme.colorSec
          : props => props.theme.colorSec};
    }
  }
`

const MainNavSubMenuItem = ({
  currentPageSlug,
  objectSlug,
  title,
  location,
}) => {
  const slugLink =
    objectSlug === "trailblazer-fund" || objectSlug === "case-studies"
      ? `/${objectSlug}`
      : `${currentPageSlug}/${objectSlug}`

  const isCurrentSub =
    slugLink === location || `${slugLink}/` === location ? true : false

  return (
    <MainNavSubMenuItemStyled isCurrentSub={isCurrentSub}>
      <Link className="sub-menu-link" to={slugLink}>
        {title}
      </Link>
    </MainNavSubMenuItemStyled>
  )
}

export default MainNavSubMenuItem
