import React from "react"
import styled from "styled-components"
import Lottie from "react-lottie"
import * as greencheck from "./greencheck.json"

import { buttonOne, colors } from "../../../Utilities"

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: greencheck.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const SuccessMessageStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.colorSecondary};
  z-index: 999999999;

  .submit-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .submit-content {
    align-self: center;
    max-width: 50rem;
    padding: 3rem;
    background: #fff;
    border-radius: 0.5rem;
    text-align: center;

    button {
      ${buttonOne};
    }
  }
`

const SuccessMessage = props => {
  return (
    <SuccessMessageStyled>
      <div className="submit-wrapper">
        <div className="submit-content">
          <p>
            You have successfully signed up for 5 Ways To Drive Your Business By
            Telling Stories E-Book.
          </p>
          <Lottie options={defaultOptions} height={200} width={200} />
          <p>
            Your free download will be sent to you in a couple of minutes, check
            your inbox. Happy Reading.
          </p>
          <button onClick={props.successAndClearForm}>Done</button>
        </div>
      </div>
    </SuccessMessageStyled>
  )
}

export default SuccessMessage
