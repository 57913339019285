import React from "react"

const CloudsGrey = () => {
  return (
    <div>
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1852.7 826.3"
      >
        <path
          id="Path_410"
          d="M283.9,826.3c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0.1-0.1,0.1h-86.6	c-0.4-0.7-0.9-1.5-1.3-2.2c-1.4,0.8-2.8,1.6-4.3,2.2h-42.7c-0.9-0.4-1.8-0.9-2.7-1.4c-0.4,0.5-0.8,0.9-1.2,1.4H1.1	c-5-20.1,7.3-40.5,27.4-45.5c10.2-2.5,20.9-0.7,29.7,5.1c-0.1-0.7-0.1-1.4-0.1-2.1c0-12.2,9.9-22,22-22c11,0,20.3,8.1,21.8,19.1	c5.2-4.1,11.6-6.5,18.2-6.8c1.9-14.7,10.2-27.7,22.6-35.7c1.3-12.1,12.1-20.9,24.2-19.6c2.9,0.3,5.7,1.2,8.2,2.5	c8.6-22.7,32.1-36.1,56.1-31.8c11-20.1,36.2-27.5,56.3-16.6c7.9,4.3,14.2,11.1,18,19.4c12.1-3.4,24.8-3.7,37-1	c-1.4-12.1,7.3-23,19.4-24.3c9.7-1.1,18.9,4.3,22.7,13.3c5.3-2.9,10.9-5.2,16.7-7c-5.5-27.3,12.2-53.9,39.5-59.5	c0.2,0,0.4-0.1,0.7-0.1c0-0.5,0-0.9,0-1.4c0-27.9,22.6-50.4,50.4-50.4c24.4,0,45.3,17.5,49.6,41.5c18.7-5.6,38.4-6.4,57.5-2.6	c-6.1-27.2,11.1-54.1,38.3-60.2c12-2.7,24.6-0.9,35.4,5.1c4.3-27.5,30-46.4,57.6-42.1c9.9,1.5,19,5.9,26.4,12.7	c-0.3-2.7-0.5-5.4-0.5-8.1c0-36.2,29.4-65.6,65.6-65.6c21,0,40.8,10.1,53.1,27.1c13.8-24.2,44.6-32.6,68.8-18.8	c3.5,2,6.8,4.4,9.7,7.2c17.9-17.6,44.5-22.9,67.8-13.5c8.5-17.1,26-27.9,45.1-27.8c0.8,0,1.7,0,2.5,0.1c-5.4-10.9-1-24,9.9-29.4	c-23.9-20.6-26.6-56.6-6-80.4c4.4-5.1,9.7-9.5,15.7-12.7c-10.8-14.8-7.5-35.5,7.3-46.3c9.7-7.1,22.5-8.3,33.4-3.3	c30.2-25.2,75.2-21.2,100.5,9.1c1,1.2,2,2.4,2.9,3.7c4.5-6.4,10.5-11.7,17.4-15.4c1.1-27.8,24.6-49.5,52.4-48.4	c12.8,0.5,24.9,5.9,33.9,15c4.3-1.9,8.9-3.3,13.5-4.1c6.6-22.5,26.2-38.6,49.5-40.8c3.6-11.6,16-18.1,27.6-14.4	c8.4,2.6,14.4,10,15.3,18.7c21.2-18.1,53-15.6,71.1,5.6c6.6,7.7,10.7,17.2,11.8,27.2c9.8,1.8,18.8,6.4,25.9,13.4	c13.8-24.2,44.6-32.7,68.8-18.9c9.9,5.6,17.5,14.4,21.8,24.9c3.7-5.2,8-9.9,12.7-14.1c-6.4-10.3-3.2-23.9,7.1-30.3	c8.5-5.3,19.5-4.2,26.7,2.7c-5.3-24,3.3-49,22.2-64.7c-24.7-19.5-29-55.4-9.4-80.1c16.7-21.2,46-27.8,70.1-15.8	c24.4-15.1,53.5-20.7,81.8-15.9v824.6H283.9z"
          fill="#ddedf2"
        />
      </svg>
    </div>
  )
}

export default CloudsGrey
