import React from "react"
import styled from "styled-components"

import { headlineThree } from "../../../Utilities/"

import StandardWrapper from "../../Shared/StandardWrapper"
import BodyCopy from "../../Shared/BodyCopy"
import DownloadDrive from "../../../Elements/Images/DownloadDrive"
import CloudsGray from "../../../Elements/SVG/CloudsGrey"

import FreeDownloadForm from "../../Shared/FreeDownloadForm"

const DownloadSection = styled.section`
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media (min-width: 1025px) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .download-clouds {
    position: absolute;
    bottom: -12rem;
    left: -20rem;
    width: 130vw;
    min-width: 100rem;
    z-index: -1;

    @media (min-width: 768px) {
      bottom: -13vw;
      width: 130vw;
    }
  }

  .download-wrapper {
    @media (min-width: 1025px) {
      max-width: 80rem;
    }
  }

  .download-image {
    @media (min-width: 1025px) {
      width: calc(33.33% - 4rem);
      margin-left: 4rem;
      padding: 0.5rem;
    }
  }

  .download-content {
    @media (min-width: 1025px) {
      width: calc(66.66%);
    }
  }

  .download-title {
    width: 100%;
    h2 {
      ${headlineThree};
      margin-top: 0;
      text-align: right;

      span {
        display: block;
      }
    }
  }

  .download-body {
    width: 100%;
    text-align: right;
  }

  .download-form {
    width: 100%;
    text-align: right;
  }
`

const Download = () => {
  return (
    <DownloadSection>
      <StandardWrapper modifierClass="download-wrapper">
        <div className="download-content">
          <div className="download-title">
            <h2>
              5 Ways To Drive Your Business <span>By Telling Stories</span>
            </h2>
          </div>
          <div className="download-body">
            <BodyCopy content="<p>Selling is storytelling now.</p>" />
          </div>
          <div className="download-form">
            <FreeDownloadForm />
          </div>
        </div>
        <div className="download-image">
          <DownloadDrive />
        </div>
      </StandardWrapper>
      <div className="download-clouds">
        <CloudsGray />
      </div>
    </DownloadSection>
  )
}

export default Download
