import React, { Component } from "react"
import styled from "styled-components"
import { headlineThree, standardWrapper, bodyCopy } from "../../Utilities"
import StandardWrapper from "../Shared/StandardWrapper"
import BodyCopy from "../Shared/BodyCopy"
import DownloadDrive from "../../Elements/Images/DownloadDrive"
import CloudsGray from "../../Elements/SVG/CloudsGrey"
import SocialFollow from "../Shared/SocialFollow"
import FreeDownloadForm from "../Shared/FreeDownloadForm"
import SwitchbackCogGrey from "../../Elements/Images/Logos/SwitchbackCogGrey"

const DownloadSection = styled.section`
  position: relative;
  margin-top: 5rem;
  padding-top: 5rem;
  padding-bottom: 0;

  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 60%,
    #ddedf2 60%,
    #ddedf2 100%
  );

  @media (min-width: 768px) {
    background: transparent;
  }

  @media (min-width: 1025px) {
    margin-top: 10rem;
    padding-top: 10rem;
    padding-bottom: 0;
  }

  .download-clouds {
    position: absolute;
    top: 0;
    left: -20rem;
    width: 130vw;
    height: 100%;
    min-width: 100rem;
    z-index: -1;

    @media (min-width: 768px) {
      top: 0rem;
      left: -20rem;
      width: 130vw;
      height: 100%;
    }

    @media (min-width: 840px) {
      top: -7.5rem;
      width: 130vw;
    }

    @media (min-width: 1130px) {
      top: -15rem;
    }

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      display: none;
    }
  }

  .download-wrapper {
    background: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 85%,
      #ddedf2 85%,
      #ddedf2 100%
    );

    @media (min-width: 768px) {
      background: transparent;
    }
    @media (min-width: 1025px) {
      max-width: 80rem;
    }
  }

  .download-image {
    @media (min-width: 768px) {
      width: calc(33.33% - 4rem);
      margin-left: 4rem;
      padding: 0.5rem;
    }
  }

  .download-content {
    @media (min-width: 768px) {
      width: calc(66.66%);
    }
  }

  .download-title {
    width: 100%;
    h2 {
      ${headlineThree};
      margin-top: 0;
      text-align: right;

      span {
        display: block;
      }
    }
  }

  .download-body {
    width: 100%;
    text-align: right;
  }

  .download-form {
    width: 100%;
    text-align: right;
  }
`

const ContactInfo = styled.div`
  margin-top: 0;
  padding-bottom: 2.5rem;
  background: linear-gradient(to bottom, #ddedf2 0%, #ddedf2 100%);

  @media (min-width: 768px) {
    margin-top: 4rem;
    padding-bottom: 2.5rem;
  }

  @media (min-width: 1220px) {
    background: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 20%,
      #ddedf2 20%,
      #ddedf2 100%
    );
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    background: linear-gradient(to bottom, #ddedf2 0%, #ddedf2 100%) !important;
  }

  .wrapper {
    ${standardWrapper};
    flex-direction: row-reverse;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }
  }

  .alt-foot-contact {
    width: Calc(100%);
    max-width: 30rem;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    @media (min-width: 768px) {
      width: Calc(50%);
      margin-right: auto;
      margin-left: 0;
    }

    h3 {
      ${bodyCopy};
      margin: 0;
      text-transform: uppercase;
      color: #707070;
      font-weight: 400;
    }

    h4,
    p {
      ${bodyCopy};
      margin: 0;
      color: #707070;
      font-weight: 400;
    }

    .alt-foot-social {
      .social-icon {
        margin: 1rem;
      }
    }
  }

  .alt-foot-cog {
    width: Calc(100%);
    max-width: 15rem;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;

    @media (min-width: 768px) {
      width: Calc(50%);
      margin-right: 0;
      margin-left: auto;
    }
  }
`

export default class FooterAlt extends Component {
  render() {
    return (
      <DownloadSection>
        <StandardWrapper modifierClass="download-wrapper">
          <div className="download-content">
            <div className="download-title">
              <h2>
                5 Ways To Drive Your Business <span>By Telling Stories</span>
              </h2>
            </div>
            <div className="download-body">
              <BodyCopy content="<p>Selling is storytelling now.</p>" />
            </div>
            <div className="download-form">
              <FreeDownloadForm />
            </div>
          </div>
          <div className="download-image">
            <DownloadDrive />
          </div>
        </StandardWrapper>

        <ContactInfo>
          <div className="wrapper">
            <div className="alt-foot-contact">
              <div>
                <h3>Contact</h3>
                <h4>Switchback Creative</h4>
                <p>Office Phone: 403.969.5879</p>
              </div>
              <div className="alt-foot-social">
                <SocialFollow />
              </div>
            </div>
            <div className="alt-foot-cog">
              <SwitchbackCogGrey />
            </div>
          </div>
        </ContactInfo>

        <div className="download-clouds">
          <CloudsGray />
        </div>
      </DownloadSection>
    )
  }
}
