import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { boxBtn, fontSizer } from "../../../Utilities"

const EmployeeStyled = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.bpTablet}) {
    display: block;
    width: 100%;
    padding: 2rem;
    text-align: center;
  }

  @media (min-width: ${props => props.theme.bpDesksm}) {
    text-align: right;
  }

  a {
    ${boxBtn}
    ${fontSizer(1.4, 1.6, 76.8, 110, 2.4)};
  }
`

const MainNavStart = () => {
  return (
    <EmployeeStyled>
      <Link to="/contact">Start a Project</Link>
    </EmployeeStyled>
  )
}

export default MainNavStart
