import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { standardWrapper, fontSizer, colors, bodyCopy } from "../../Utilities/"

import SocialFollow from "../Shared/SocialFollow"
import SwbCog from "../../Elements/Images/Logos/SwitchbackCog"
import FreeDownloadForm from "../Shared/FreeDownloadForm"

const FooterStyled = styled.footer`
  padding: 2.5rem 0;
  background: #3c3c3c;
`

const Wrapper = styled.div`
  ${standardWrapper}
  align-items: center;
`

const FooterContact = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media (min-width: 768px) {
    width: calc(70% - 2.5rem);
    margin-right: 2.5rem;
  }
`

const CogLogoContainer = styled.div`
  align-self: center;
  width: calc(100%);

  @media (min-width: 768px) {
    width: calc(30%);
  }

  .gatsby-image-wrapper {
    max-width: 15rem;
    margin: 0 auto 5rem;

    @media (min-width: 768px) {
      max-width: 17rem;
      margin: 0;
    }
  }
`

const ContactInfoContainer = styled.div`
  align-self: center;
  width: calc(100%);

  @media (min-width: 768px) {
    width: calc(70%);
  }
`

const StartAProject = styled.div`
  text-align: left;
  margin-bottom: 2.5rem;

  p {
    ${bodyCopy};
    margin: 0;
    color: #fff;
  }

  a {
    ${fontSizer(1.8, 2, 76.8, 110, 2)};
    position: relative;
    display: inline-block;
    margin-bottom: 1rem;
    padding-bottom: 0;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;

    &::after {
      display: block;
      position: absolute;
      right: 0;
      bottom: -0.5rem;
      left: 0;
      height: 0.1rem;
      background-color: ${colors.colorAccent};
      content: "";
    }
  }
`

const EmailNumber = styled.div`
  width: 100%;
  a {
    ${fontSizer(1.4, 1.8, 76.8, 110)}
    display: block;
    color: #fff;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    text-transform: uppercase;
    line-height: 1.5;
    letter-spacing: 0.94px;
  }
`

const FooterSocialFollow = styled.div`
  width: 100%;
`

const FooterNav = styled.div`
  width: 100%;

  a {
    ${fontSizer(1.2, 1.6, 76.8, 110)}
    position: relative;
    color: #fff;
    font-weight: 300;
  }

  a:first-of-type {
    margin-right: 2rem;

    &::after {
      display: block;
      position: absolute;
      top: 0;
      right: -1.25rem;
      content: "|";
    }
  }
`

const FooterDownload = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5rem;

  @media (min-width: 768px) {
    width: calc(30% - 2.5rem);
    margin-top: 0;
    margin-left: 2.5rem;
  }
`

const FooterDownloadTitle = styled.div`
  width: 100%;

  .download-title {
    ${fontSizer(1.4, 1.6, 76.8, 110, 3)}
    margin-bottom: 1rem;
    color: #fff;
    text-align: center;

    @media (min-width: 768px) {
      text-align: right;
    }

    span {
      @media (min-width: 768px) {
        display: block;
      }
    }
  }

  .download-field {
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 0;

    label {
      color: #fff;
    }
  }

  .download-field-name {
    margin-bottom: 2rem;
  }

  .download-form--input {
    width: 100% !important;
    margin: 0 auto;
    padding-top: 1rem;

    label {
      color: #fff;
    }
  }

  .download-form--btn {
    button {
      ${fontSizer(1.4, 1.4, 76.8, 110, 2)};
      width: 100%;
      text-align: center;
    }
  }
`

const Footer = () => {
  return (
    <FooterStyled>
      <Wrapper>
        <FooterContact>
          <CogLogoContainer>
            <SwbCog />
          </CogLogoContainer>
          <ContactInfoContainer>
            <StartAProject>
              <div className="paint-button-wrapper">
                <p>Start something amazing.</p>
                <Link to="/contact">Start a Project</Link>
              </div>
            </StartAProject>
            <EmailNumber>
              <a href="mailto:hello@switchbackcreative.ca">
                hello@switchbackcreative.ca
              </a>
              <a href="tel:14039695879">403-969-5879</a>
            </EmailNumber>
            <FooterSocialFollow>
              <SocialFollow />
            </FooterSocialFollow>
            <FooterNav>
              <Link to="/privacy-policy/">Privacy Policy</Link>
              <Link to="/disclaimer/">Disclaimer</Link>
            </FooterNav>
          </ContactInfoContainer>
        </FooterContact>
        <FooterDownload>
          <FooterDownloadTitle>
            <p className="download-title">
              5 Ways To Drive Your Business <span>By Telling Stories</span>
            </p>
            <FreeDownloadForm />
          </FooterDownloadTitle>
        </FooterDownload>
      </Wrapper>
    </FooterStyled>
  )
}

export default Footer
