import React, { Component } from "react"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"

import { colors, buttonOne, fontSizer } from "../../Utilities"

import ErrorWarning from "../Shared/FormParts/ErrorWarning"
import Submitting from "../Shared/FormParts/Submitting"
import SuccessMessage from "../Shared/FormParts/SuccessMessage"

const FormStyled = styled.form`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .download-form--input {
    position: relative;
    width: calc(100% - 2rem);
    margin: 0 1rem;
    padding-top: 2rem;

    @media (min-width: 768px) {
      width: calc(50% - 2rem);
    }

    label {
      ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
      display: inline-block;
      width: 100%;
      text-align: left;

      span {
        ${fontSizer(1, 1, 76.8, 150, 1.4)};
      }
    }

    #field-error-message {
      ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
      position: absolute;
      top: 0;
      left: 0rem;
      color: red !important;
      z-index: 10;
    }

    input {
      width: 100%;
      padding: 1rem;
      border: 0.1rem solid #707070;

      &:focus {
        border-color: ${colors.colorSecondary};
        box-shadow: 0 0 0.1rem 0.1rem ${colors.colorSecondary};
        outline: none;
      }
    }
  }

  .download-form--btn {
    margin-top: 2rem;
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      text-align: right;
    }

    button {
      ${buttonOne};
    }
  }

  .download-form--name {
    margin-right: 0;
    margin-left: 0;

    @media (min-width: 768px) {
      margin-right: 2rem;
      margin-left: 0;
    }
  }

  .download-form--email {
    margin-right: 0;
    margin-left: 0;

    @media (min-width: 768px) {
      margin-right: 0;
      margin-left: 2rem;
    }
  }
`

export default class FreeDownload extends Component {
  constructor(props) {
    super(props)
    this.onChangeFields = this.onChangeFields.bind(this)
    this.submitTheForm = this.submitTheForm.bind(this)
    this.emailSentSuccess = this.emailSentSuccess.bind(this)
    this.formHasErrors = this.formHasErrors.bind(this)
    this.fixTheFormErrors = this.fixTheFormErrors.bind(this)
    this.successAndClearForm = this.successAndClearForm.bind(this)

    this.state = {
      FNAME: "",
      EMAIL: "",
      submitting: false,
      errorWarnDisplay: false,
      success: false,
      errors: "",
    }
  }

  onChangeFields(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  submitTheForm(e) {
    e.preventDefault()

    this.setState(prevState => {
      return {
        ...prevState,
        submitting: true,
        errors: "",
      }
    })

    const listFields = {}
    listFields.FNAME = this.state.FNAME

    addToMailchimp(this.state.EMAIL, listFields)
      .then(data => {
        setTimeout(() => {
          if (data.result !== "error") {
            this.emailSentSuccess(data.msg)
          } else {
            this.formHasErrors(data.msg)
          }
        }, 2000)
      })
      .catch(err => {
        console.log(err)
      })
  }

  fixTheFormErrors() {
    this.setState(prevState => {
      return {
        ...prevState,
        errorWarnDisplay: false,
        submitting: false,
      }
    })
  }

  emailSentSuccess(res) {
    this.setState(prevState => {
      return {
        ...prevState,
        submitting: false,
        success: true,
      }
    })
  }

  formHasErrors(errorsFields) {
    this.setState(prevState => {
      return {
        ...prevState,
        submitting: false,
        errorWarnDisplay: true,
        errorMsg: errorsFields,
      }
    })
  }

  successAndClearForm() {
    this.setState(prevState => {
      return {
        ...prevState,
        FNAME: "",
        EMAIL: "",
        submitting: false,
        errorWarnDisplay: false,
        success: false,
        errors: "",
      }
    })
  }

  render() {
    const nameErrorMessage = false
    const emailErrorMessage = false

    return (
      <FormStyled onSubmit={this.submitTheForm}>
        <div className="download-form--input download-form--name">
          <label htmlFor="FNAME">name* <span>(required)</span></label>
          {nameErrorMessage}
          <input
            id="FNAME"
            name="FNAME"
            type="text"
            required={true}
            value={this.state.FNAME}
            onChange={this.onChangeFields}
          />
        </div>
        <div className="download-form--input download-form--email">
          <label htmlFor="EMAIL">email* <span>(required)</span></label>
          {emailErrorMessage}
          <input
            id="EMAIL"
            name="EMAIL"
            type="email"
            required={true}
            value={this.state.EMAIL}
            onChange={this.onChangeFields}
          />
        </div>
        <div className="download-form--btn">
          <button>Free Download</button>
        </div>
        {this.state.submitting && <Submitting />}
        {this.state.errorWarnDisplay && (
          <ErrorWarning
            fixTheFormErrors={this.fixTheFormErrors}
            errorMessage={this.state.errorMsg}
          />
        )}
        {this.state.success && (
          <SuccessMessage successAndClearForm={this.successAndClearForm} />
        )}
      </FormStyled>
    )
  }
}
