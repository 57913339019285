export default [
  {
    title: "Facebook",
    label: "facebook",
    url: "https://www.facebook.com/switchbackcreative/",
  },
  {
    title: "Twitter",
    label: "twitter",
    url: "https://twitter.com/switchback4ever",
  },
  {
    title: "Instagram",
    label: "instagram",
    url: "https://www.instagram.com/switchback4ever/?hl=en",
  },
  {
    title: "Linkedin",
    label: "linkedin",
    url: "https://www.linkedin.com/company/switchback-creative/",
  },
]
