/* eslint-disable */
import React, { Component } from "react"
import styled, { keyframes } from "styled-components"
import { TimelineMax, Power2, CSSPlugin, AttrPlugin } from "gsap/all"

import StartHere from "../../../../Elements/Images/StartHere"

import CellPhoneBranding from "../../../../Elements/Images/CellPhoneBranding"
import CellPhoneWebDev from "../../../../Elements/Images/CellPhoneWebDev"
import CellPhoneMarketing from "../../../../Elements/Images/CellPhoneMarketing"

const plugins = [CSSPlugin, AttrPlugin]

const shake = keyframes`
 10%, 20% {
    transform: translate3d(-1px, 0, 0);
  }
  
  11%, 19% {
    transform: translate3d(2px, 0, 0);
  }

  13%, 15%, 17% {
    transform: translate3d(-4px, 0, 0);
  }

  14%, 16% {
    transform: translate3d(4px, 0, 0);
  }

  21% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

const PhoneAnimationContainer = styled.div`
  position: relative;
  min-height: 35rem;
  width: 100%;

  @media (min-width: 768px) {
    width: calc(33.33%);
    min-height: 35rem;
    margin-right: 66.66%;
  }
`

const PhoneInnnerWrap = styled.div`
  position: relative;
  transform: rotate3d(50deg);
`

const SinglePhone = styled.div`
  position: absolute;
  top: 2rem;
  right: 0;
  left: 0;
  max-width: 17.5rem;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;

  @media (min-width: 768px) {
    top: 5rem;
    max-width: 20rem;
  }

  &.cell-phone-start {
    animation-name: ${shake};
    animation-timing-function: cubic-bezier(0.36, 0.07, 0.19, 0.97);
    animation-iteration-count: infinite;
    animation-duration: 4s;
    animation-delay: 3s;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
    z-index: 20;
  }

  &.cell-phone-brand {
    transform: scale(1) translate(0);
    z-index: ${props => (props.activeNum === 1 ? 10 : 2)} !important;
  }

  &.cell-phone-website {
    transform: scale(1) translate(0);
    z-index: ${props => (props.activeNum === 2 ? 10 : 2)} !important;
  }

  &.cell-phone-market {
    transform: scale(1) translate(0);
    z-index: ${props => (props.activeNum === 3 ? 10 : 2)} !important;
  }

  &:hover {
    cursor: pointer;
  }
`

export default class PhoneAnimation extends Component {
  constructor(props) {
    super(props)

    this.startTheCellAnimations = this.startTheCellAnimations.bind(this)
    this.revealTheCellPhones = this.revealTheCellPhones.bind(this)
    this.rotateTheCellPhone = this.rotateTheCellPhone.bind(this)
    this.reactivateTheClickEvent = this.reactivateTheClickEvent.bind(this)

    this.revealTl = new TimelineMax({ paused: true })
    this.rotateOneTl = new TimelineMax({ paused: true })
    this.rotateTwoTl = new TimelineMax({ paused: true })
    this.rotateThreeTl = new TimelineMax({ paused: true })

    this.state = {
      animationStart: false,
      animationChanging: false,
      currentPosition: 0,
    }
  }

  componentDidMount() {
    this.revealTl
      .add("start")
      .to(this.phoneStart, 0.1, { autoAlpha: 0 })
      .add("start2")
      .to(
        this.phoneWebsite,
        0.25,
        { x: "75px", zIndex: 2, scale: 1, ease: Power2.easeOut },
        "start2"
      )
      .to(
        this.phoneMarket,
        0.25,
        { x: "-75px", zIndex: 2, scale: 1, ease: Power2.easeOut },
        "start2"
      )
      .to(
        this.phoneBrand,
        0.25,
        { x: "0px", zIndex: 10, scale: 1.1, ease: Power2.easeOut },
        "start2"
      )

    this.rotateOneTl
      .add("step3")
      .to(
        this.phoneWebsite,
        0.25,
        { x: "75px", zIndex: 2, scale: 1, ease: Power2.easeOut },
        "step3"
      )
      .to(
        this.phoneMarket,
        0.25,
        { x: "-75px", zIndex: 2, scale: 1, ease: Power2.easeOut },
        "step3"
      )
      .to(
        this.phoneBrand,
        0.25,
        { x: "0px", zIndex: 10, scale: 1.1, ease: Power2.easeOut },
        "step3"
      )
      .call(this.reactivateTheClickEvent)

    this.rotateTwoTl
      .add("step1")
      .to(
        this.phoneWebsite,
        0.25,
        { x: "0px", zIndex: 10, scale: 1.1, ease: Power2.easeOut },
        "step1"
      )
      .to(
        this.phoneMarket,
        0.25,
        { x: "75px", zIndex: 2, scale: 1, ease: Power2.easeOut },
        "step1"
      )
      .to(
        this.phoneBrand,
        0.25,
        { x: "-75px", zIndex: 2, scale: 1, ease: Power2.easeOut },
        "step1"
      )
      .call(this.reactivateTheClickEvent)

    this.rotateThreeTl
      .add("step2")
      .to(
        this.phoneWebsite,
        0.25,
        { x: "-75px", zIndex: 2, scale: 1, ease: Power2.easeOut },
        "step2"
      )
      .to(
        this.phoneMarket,
        0.25,
        { x: "0px", zIndex: 10, scale: 1.1, ease: Power2.easeOut },
        "step2"
      )
      .to(
        this.phoneBrand,
        0.25,
        { x: "75px", zIndex: 2, scale: 1, ease: Power2.easeOut },
        "step2"
      )
      .call(this.reactivateTheClickEvent)
  }

  reactivateTheClickEvent() {
    this.setState(prevState => {
      return {
        ...prevState,
        animationChanging: false,
      }
    })
  }

  rotateTheCellPhone() {
    switch (this.state.currentPosition) {
      case 1:
        this.rotateOneTl.restart()
        break
      case 2:
        this.rotateTwoTl.restart()
        break
      case 3:
        this.rotateThreeTl.restart()
        break
    }
  }

  revealTheCellPhones() {
    this.props.changeCurrentPosition(this.state.currentPosition)
    if (this.state.animationStart) {
      this.setState(prevState => {
        return {
          ...prevState,
          animationChanging: true,
        }
      }, this.rotateTheCellPhone())
    } else {
      this.setState(
        prevState => {
          return {
            animationStart: true,
          }
        },
        function() {
          this.revealTl.play()
        }
      )
    }
  }

  startTheCellAnimations() {
    if (this.state.animationChanging) return

    this.setState(prevState => {
      return {
        currentPosition:
          prevState.currentPosition >= 3 ? 1 : prevState.currentPosition + 1,
      }
    }, this.revealTheCellPhones)
  }

  render() {
    return (
      <PhoneAnimationContainer>
        <PhoneInnnerWrap onClick={this.startTheCellAnimations}>
          <SinglePhone
            activeState={this.state.animationStart}
            activeNum={this.state.currentPosition}
            className="cell-phone-start"
            ref={div => (this.phoneStart = div)}
          >
            <StartHere />
          </SinglePhone>
          <SinglePhone
            activeNum={this.state.currentPosition}
            className="cell-phone-brand"
            ref={div => (this.phoneBrand = div)}
          >
            <CellPhoneBranding />
          </SinglePhone>
          <SinglePhone
            activeNum={this.state.currentPosition}
            className="cell-phone-market"
            ref={div => (this.phoneMarket = div)}
          >
            <CellPhoneMarketing />
          </SinglePhone>
          <SinglePhone
            activeNum={this.state.currentPosition}
            className="cell-phone-website"
            ref={div => (this.phoneWebsite = div)}
          >
            <CellPhoneWebDev />
          </SinglePhone>
        </PhoneInnnerWrap>
      </PhoneAnimationContainer>
    )
  }
}
