import { colors } from "./index"
import { fonts, fontSizer } from "./index"

export const mainPara = `
  ${fontSizer(1.8, 2, 76.8, 160)}
  color: ${colors.black};
  font-family: ${fonts.fontPrimary};
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: 1.4px;  
`

export const bodyCopy = `
  ${fontSizer(1.8, 2, 76.8, 110, 2)}
  font-family: ${fonts.fontPrimary};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
`

export const bigPara = `
  ${mainPara}  
  ${fontSizer(2, 2.2, 76.8, 160)}
  line-height: 1.33;
  letter-spacing: 1.42px;
`

export const striptCallOut = `
  ${fontSizer(2, 3.5, 76.8, 110, 2.4)}
  color: #505050;
  font-family: ${fonts.fontTertiary};  
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
`
