import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import { headlineThree, fontSizer, bodyCopy } from "../../../../Utilities/"

const getData = graphql`
  {
    work: allWordpressWpPortfolio(
      sort: { fields: [date], order: [DESC] }
      limit: 1
      skip: 2
    ) {
      edges {
        node {
          title
          slug
          acf {
            tag_line
            main_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const StyledThirdPortfolio = styled(BackgroundImage)`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 30rem;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @media (min-width: 768px) {
    min-height: auto;
  }

  h2 {
    ${headlineThree};
    ${fontSizer(2, 3.5, 76.8, 160, 2.2)}
    position: relative;
    align-self: center;
    padding: 2rem 9vw;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    z-index: 10;
  }

  p {
    ${bodyCopy};
    position: relative;
    align-self: center;
    padding: 2rem 9vw;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    z-index: 10;
  }

  .port-overlay {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    transition: all 0.3s ease-in-out;
    transform: translate(-100%, -100%);
    opacity: 0.59;
    z-index: 5;
  }

  &:hover {
    cursor: pointer;
    .port-overlay {
      transform: translate(0%, 0%) !important;
      cursor: pointer;
    }
  }
`

const ThirdPortfolio = () => {
  const data = useStaticQuery(getData)
  const title = data.work.edges[0].node.title
  const tagLine = data.work.edges[0].node.acf.tag_line
  const imageData =
    data.work.edges[0].node.acf.main_image.localFile.childImageSharp.fluid
  return (
    <StyledThirdPortfolio Tag="div" fluid={imageData}>
      <Link to={`/portfolio/${data.work.edges[0].node.slug}`}>
        <div className="port-overlay">
          <div>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <p>{tagLine}</p>
          </div>
        </div>
      </Link>
    </StyledThirdPortfolio>
  )
}

export default ThirdPortfolio
