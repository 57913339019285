/* eslint-disable */
import React, { Component } from "react"
import styled from "styled-components"
import { TweenMax, Power0, CSSPlugin, AttrPlugin } from "gsap/all"

import { bodyCopy, colors, headlineOne } from "../../../../Utilities/"

const plugins = [CSSPlugin, AttrPlugin]

const StartContent = styled.div`
  position: absolute;
  top: 72.5%;
  right: 0;
  width: 100%;
  padding: 0 2rem;
  align-self: center;
  transition: all 0.5s ease-out;
  transform: translate(0%, -50%);
  opacity: 0;
  visibility: hidden;

  @media (min-width: 375px) {
    top: 72.5%;
  }

  @media (min-width: 768px) {
    top: 50%;
    width: calc(66.66% - 5.5rem);
    margin-left: 5.5rem;
    padding: 0;
  }
`

const StartTitleContainer = styled.div`
  width: 100%;
`

const StartTitle = styled.h2`
  ${headlineOne(colors.white)}
`

const StartParaContainer = styled.div``

const StartPara = styled.p`
  ${bodyCopy}
  color: ${colors.white};
`

export default class ContentAnimation extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    TweenMax.set(this.contentDont, { autoAlpha: 1, x: 0 })
    TweenMax.set(
      [this.contentWebsite, this.contentBranding, this.contentMarketing],
      { autoAlpha: 0, x: 100 }
    )
  }

  componentDidUpdate() {
    const whichActive = this.props.activeContent

    if (whichActive === 1) {
      TweenMax.to(
        [this.contentDont, this.contentMarketing, this.contentWebsite],
        0.25,
        { autoAlpha: 0, x: 200, y: "-50%" }
      )

      TweenMax.to(this.contentBranding, 0.35, {
        autoAlpha: 1,
        x: 0,
      })
    } else if (whichActive === 2) {
      TweenMax.to(
        [this.contentDont, this.contentMarketing, this.contentBranding],
        0.25,
        { autoAlpha: 0, x: 200, y: "-50%" }
      )

      TweenMax.to(this.contentWebsite, 0.35, {
        autoAlpha: 1,
        x: 0,
      })
    } else if (whichActive === 3) {
      TweenMax.to(
        [this.contentDont, this.contentWebsite, this.contentBranding],
        0.25,
        { autoAlpha: 0, x: 200, y: "-50%" }
      )

      TweenMax.to(this.contentMarketing, 0.35, {
        autoAlpha: 1,
        x: 0,
      })
    }
  }

  render() {
    const startContent = (
      <StartContent
        className={this.props.activeContent === 0 && "active-content"}
        ref={div => (this.contentDont = div)}
      >
        <StartTitleContainer>
          <StartTitle
            dangerouslySetInnerHTML={{
              __html: "Don't know <br /> where to start?",
            }}
          />
        </StartTitleContainer>
        <StartParaContainer>
          <StartPara
            dangerouslySetInnerHTML={{
              __html:
                "We are here to guide you through the seemingly endless digital marketing trail. We have three sets of tools that we use to SHARE YOUR STORY with the world.",
            }}
          />
        </StartParaContainer>
      </StartContent>
    )
    const bandingContent = (
      <StartContent
        className={this.props.activeContent === 1 && "active-content"}
        ref={div => (this.contentBranding = div)}
      >
        <StartTitleContainer>
          <StartTitle
            dangerouslySetInnerHTML={{ __html: "Design & Branding" }}
          />
        </StartTitleContainer>
        <StartParaContainer>
          <StartPara
            dangerouslySetInnerHTML={{
              __html:
                "Your brand is the face of your company, whether you are new company or need to revive your existing brand, we will walk you through a process to create a long term vision for your brand.",
            }}
          />
        </StartParaContainer>
      </StartContent>
    )

    const websiteContent = (
      <StartContent
        className={this.props.activeContent === 2 && "active-content"}
        ref={div => (this.contentWebsite = div)}
      >
        <StartTitleContainer>
          <StartTitle
            dangerouslySetInnerHTML={{ __html: "Website Development" }}
          />
        </StartTitleContainer>
        <StartParaContainer>
          <StartPara
            dangerouslySetInnerHTML={{
              __html:
                "Using modern technologies we craft custom web solutions, NEVER using templates, to build fully responsive, user friendly web experiences.",
            }}
          />
        </StartParaContainer>
      </StartContent>
    )

    const marketingContent = (
      <StartContent
        className={this.props.activeContent === 3 && "active-content"}
        ref={div => (this.contentMarketing = div)}
      >
        <StartTitleContainer>
          <StartTitle
            dangerouslySetInnerHTML={{ __html: "Marketing & Advertising" }}
          />
        </StartTitleContainer>
        <StartParaContainer>
          <StartPara
            dangerouslySetInnerHTML={{
              __html:
                "Strong marketing sets the stage for big growth opportunities for your business. We will help you create a proactive approach to tackling the big things in your business that need planning and execution.",
            }}
          />
        </StartParaContainer>
      </StartContent>
    )
    return (
      <>
        {startContent}
        {bandingContent}
        {websiteContent}
        {marketingContent}
      </>
    )
  }
}
