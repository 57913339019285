import React from "react"
import styled from "styled-components"
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa"

import { colors } from "../../Utilities"
import socialMediaData from "./SharedData/socialMediaData"

const SocialFollowStyled = styled.div`
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .social-icon {
    position: relative;
    width: 4rem;
    height: 4rem;
    margin: 1rem;

    &:first-of-type {
      margin-left: 0;
    }

    a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0.75rem;
      color: #fff;

      &:hover {
        background-color: ${colors.colorSecondary};
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__facebook {
      a {
        background-color: #3b5998;
      }
    }

    &__twitter {
      a {
        background-color: #1da1f2;
      }
    }

    &__instagram {
      a {
        background-color: #405de6;
      }
    }

    &__linkedin {
      a {
        background-color: #0077b5;
      }
    }
  }
`

const SocialFollow = () => {
  return (
    <SocialFollowStyled>
      <div className="wrapper">
        {socialMediaData.map((icon, index) => {
          const iconDisplay =
            icon.label === "facebook" ? (
              <FaFacebookF />
            ) : icon.label === "twitter" ? (
              <FaTwitter />
            ) : icon.label === "instagram" ? (
              <FaInstagram />
            ) : icon.label === "linkedin" ? (
              <FaLinkedinIn />
            ) : (
              false
            )
          return (
            <div
              className={`social-icon social-icon__${icon.label}`}
              key={index}
            >
              <a target="_blank" rel="noopener noreferrer" href={icon.url}>
                {iconDisplay}
              </a>
            </div>
          )
        })}
      </div>
    </SocialFollowStyled>
  )
}

export default SocialFollow
