import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import HeaderScene from "../components/PageParts/Home/HeaderScene"
import Intro from "../components/PageParts/Home/Intro"
import Start from "../components/PageParts/Home/Start"
import WhatWeDo from "../components/PageParts/Home/WhatWeDo"
import Download from "../components/PageParts/Home/Download"
import RecentWork from "../components/PageParts/Home/RecentWork"
import CompanyWeKeep from "../components/PageParts/Home/CompanyWeKeep"
import IntelNews from "../components/PageParts/Home/IntelNews"
import Testimonials from "../components/Shared/Testimonials"

const IndexPage = props => {
  const { posts, logos } = props.data
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title="Switchback Creative Inc. - Print & Web Design - Marketing & Brand Identity"
        description="Switchback Creative. Print and web design that will create a community of loyal customers for your business. Calgary, Airdrie and Southern Alberta."
        location={props.location.pathname}
      />
      <HeaderScene />
      <Intro />
      <Start />
      <WhatWeDo />
      <Download />
      <RecentWork />
      <CompanyWeKeep logos={logos} />
      <IntelNews posts={posts} />
      <Testimonials />
    </Layout>
  )
}

export const homeQuery = graphql`
  {
    posts: allWordpressPost(sort: { fields: [date], order: [DESC] }, limit: 3) {
      edges {
        node {
          title
          id
          slug
          excerpt
          date(formatString: "MMMM D, Y")
          categories {
            name
          }
          featured_media {
            source_url
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    logos: allWordpressWpClientLogos {
      edges {
        node {
          title
          id
          acf {
            _swb_client_url
            _swb_client_logo {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
