import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import MainNavItem from "./MainNavItem"
import MainNavStart from "./MainNavStart"

const MainNavStyled = styled.nav`
  position: relative;
  width: 100%;
  margin: 2rem auto;
  z-index: 10;

  @media (min-width: ${props => props.theme.bpDesksm}) {
    width: calc(80%);
  }
`

const MainNavUl = styled.ul`
  display: none;

  @media (min-width: ${props => props.theme.bpTablet}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  @media (min-width: ${props => props.theme.bpDesksm}) {
    justify-content: flex-end;
  }
`

const MainNav = ({ location }) => {
  const displayStartButton =
    location === "/contact" ? false : location === "/contact/" ? false : true

  return (
    <StaticQuery
      query={graphql`
        {
          wordpressWpApiMenusMenusItems(name: { eq: "Main Menu" }) {
            items {
              wordpress_id
              title
              object_slug
              target
              type
              url
              wordpress_children {
                wordpress_id
                title
                object_slug
                url
              }
            }
          }
        }
      `}
      render={data => {
        const menuItems = data.wordpressWpApiMenusMenusItems.items
        return (
          <MainNavStyled>
            <MainNavUl>
              {menuItems.map((item, index) => {
                return <MainNavItem key={index} {...item} location={location} />
              })}
            </MainNavUl>
            {displayStartButton && <MainNavStart />}
          </MainNavStyled>
        )
      }}
    />
  )
}

export default MainNav
