import React from "react"
import styled from "styled-components"

import Post from "./SinglePost/Post"
import TitleUnderline from "../../Shared/TitleUnderline"
import StandardWrapper from "../../Shared/StandardWrapper"

const IntelNewsSection = styled.section`
  .intel-title {
    width: 100%;
    margin-bottom: 2.5rem;
    text-align: center;
  }

  .posts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100%);
  }
`

const IntelNews = props => {
  const {
    posts: { edges: posts },
  } = props
  return (
    <IntelNewsSection>
      <StandardWrapper>
        <div className="intel-title">
          <TitleUnderline title="Intel & News" />
        </div>
        <div className="posts-container">
          {posts.map(post => {
            return <Post key={post.node.id} post={post.node} />
          })}
        </div>
      </StandardWrapper>
    </IntelNewsSection>
  )
}

export default IntelNews
