const theme = {
  colorPrim: "#004565",
  colorSec: "#35C567",
  colorTert: "#283F71",
  colorAcc: "#FFB718",
  white: "#fff",
  grey: "#797e83",
  paraGrey: "#696969;",
  greyLight: "#eee",
  black: "#000",
  strongred: "#f00",
  maxWidth: "1000px",
  baseLineHeight: "1.5",
  bpTablet: "768px",
  bpDesksm: "1025px",
  bpDeskmd: "1200px",
  bpDesklg: "1600px",
  bpMax: "1900px",
  fontPrim: "Lato",
  fontSec: "Ubuntu",
  fontTer: "Shadows Into Light",
  fontAwesome: "FontAwesome",
}

export default theme
