import React from "react"
import styled from "styled-components"

import ClientLogo from "./ClientLogo/ClientLogo"
import StandardWrapper from "../../Shared/StandardWrapper"
import TitleUnderline from "../../Shared/TitleUnderline"

const CompanyWeKeepSection = styled.section`
  margin-top: 5rem;
  margin-bottom: 5rem;

  .companykeep-title {
    h2 {
      color: #3a3b3b;
    }
  }
`

const ClientLogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`

const CompanyWeKeep = props => {
  const { logos } = props

  return (
    <CompanyWeKeepSection>
      <StandardWrapper>
        <div className="companykeep-title">
          <TitleUnderline title="Company We Keep" />
        </div>
        <ClientLogosContainer>
          {logos.edges.map(logo => {
            return <ClientLogo logo={logo.node} key={logo.node.id} />
          })}
        </ClientLogosContainer>
      </StandardWrapper>
    </CompanyWeKeepSection>
  )
}

export default CompanyWeKeep
