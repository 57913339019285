export const colorPrimary = "#004565"
export const colorSecondary = "#35C567"
export const colorTertiary = "#283F71"
export const colorAccent = "#FFB718"
export const cloudBlue = "rgba(0, 69, 101, 0.1)"
export const sliver = "#707070"
export const sliverSand = "#C7C8CA"
export const navyTint = "#1D6383"
export const white = "#fff"
export const black = "#000"
export const strongRed = "#f00"

export default {
  colorPrimary,
  colorSecondary,
  colorTertiary,
  colorAccent,
  cloudBlue,
  sliver,
  sliverSand,
  navyTint,
  white,
  black,
  strongRed,
}
