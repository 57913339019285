import React from "react"
import { Link } from "gatsby"
import BgImg from "gatsby-background-image"
import styled from "styled-components"

import {
  headlineThree,
  bodyCopy,
  buttonTwo,
  colors,
  fontSizer,
} from "../../../../Utilities"

const PostStyled = styled.div`
  position: relative;
  width: 100%;
  max-width: 37.5rem;
  margin: 4rem auto;
  padding-bottom: 5rem;

  @media (min-width: 768px) {
    width: calc(33.3333% - 4rem);
    margin: 1rem 2rem;
  }

  @media (min-width: 1025px) {
    width: calc(33.3333% - 4rem);
    margin: 1rem 2rem;
  }

  .featured-image {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 37.5rem;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1 !important;

    @media (min-width: 768px) {
      height: 20rem;
    }

    @media (min-width: 1025px) {
      height: 33.3333vw;
      max-height: 29.6rem;
    }
  }

  .post-category {
    margin-top: 1rem;
    p {
      ${bodyCopy};
      margin-bottom: 0;
      color: ${colors.colorSecondary};
      text-transform: uppercase;
    }
  }

  .post-title {
    margin-bottom: 4rem;
    h2 {
      ${headlineThree()};
      ${fontSizer(2, 3, 76.8, 110, 2.2)}
      color: ${colors.colorPrimary};
      text-transform: uppercase;
    }
  }

  .post-date {
    margin-bottom: 0.5rem;

    p {
      ${bodyCopy};
      margin: 0;
      color: #585858;
      text-transform: uppercase;
    }
  }

  .post-excerpt {
    p {
      ${bodyCopy};
      ${fontSizer(1.4, 2, 76.8, 110, 2)}
      color: #585858;
    }
  }


  .post-button {
    position: absolute;
    right: 0;
    bottom: 1rem;
    left: 0;
    margin: 0 auto;

    a {
      ${buttonTwo};
    }
  }

`

const Post = props => {
  const { post } = props
  return (
    <PostStyled>
      <BgImg
        className="featured-image"
        Tag="div"
        fluid={post.featured_media.localFile.childImageSharp.fluid}
      />
      <div className="post-category">
        <p>{post.categories[0].name}</p>
      </div>
      <div className="post-title">
        <h2 dangerouslySetInnerHTML={{ __html: post.title }} />
      </div>
      <div className="post-date">
        <p>{post.date}</p>
      </div>
      <div
        className="post-excerpt"
        dangerouslySetInnerHTML={{ __html: post.excerpt }}
      />
      <div className="post-button">
        <Link to={`/blog/${post.slug}`}>Read More</Link>
      </div>
    </PostStyled>
  )
}

export default Post
