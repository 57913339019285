import React from "react"
import styled from "styled-components"
import { striptCallOut } from "../../Utilities/"

const ScriptCallOut = ({ content, className }) => {
  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
  )
}

export default styled(ScriptCallOut)`
  .call-out-text,
  p {
    ${striptCallOut}
  }
`
