import React from "react"
import styled from "styled-components"

import PhoneAnimation from "./StartAnimation/PhoneAnimation"
import ContentAnimation from "./StartAnimation/ContentAnimation"

import { standardWrapper, colors } from "../../../Utilities/"

const StartStyled = styled.section`
  margin-top: 3rem;
  margin-bottom: 3rem;
  background: ${colors.colorPrimary};

  @media (min-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`

const Wrapper = styled.div`
  ${standardWrapper}
  flex-direction: row-reverse;
  position: relative;
  min-height: 80rem;

  @media (min-width: 375px) {
    min-height: 75rem;
  }

  @media (min-width: 768px) {
    min-height: auto;
  }
`

class Start extends React.Component {
  constructor(props) {
    super(props)
    this.changeTheActiveContent = this.changeTheActiveContent.bind(this)

    this.state = {
      activeContent: 0,
    }
  }

  changeTheActiveContent(current) {
    this.setState(prevState => {
      return {
        ...prevState,
        activeContent: current,
      }
    })
  }

  render() {
    return (
      <StartStyled>
        <Wrapper>
          <ContentAnimation activeContent={this.state.activeContent} />
          <PhoneAnimation changeCurrentPosition={this.changeTheActiveContent} />
        </Wrapper>
      </StartStyled>
    )
  }
}

export default Start
