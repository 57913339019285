import React from "react"
import styled from "styled-components"

import StandardWrapper from "../../Shared/StandardWrapper"
import MainTitle from "../../Shared/MainTitle"
import BodyCopy from "../../Shared/BodyCopy"
import StyledScriptCallOut from "../../Shared/ScriptCallOut"

import Headphones from "../../../Elements/Images/Headphones"

import CompassDraw from "./CompassDraw"
import AllCards from "./WhatWeDoCard/AllCards"

const WhatWeDoSection = styled.section`
  padding-top: 10rem;

  @media (min-width: 768px) {
    padding-top: 15rem;
  }

  @media (min-width: 1025px) {
    padding-top: 10rem;
  }

  .what-we-do-content {
    position: relative;
    max-width: 70rem;
    margin: 0 auto;

    .what-compass-svg {
      display: none;

      @media (min-width: 975px) {
        display: block;
        position: absolute;
        top: 4rem;
        left: -14rem;
        width: 10rem;
      }

      svg {
        width: 100%;
        max-width: 100%;
      }
    }

    .what-headphones {
      position: absolute;
      top: -14rem;
      right: -30vw;
      width: 35rem;

      @media (min-width: 768px) {
        top: -17rem;
        right: -45vw;
        width: 55rem;
      }

      @media (min-width: 1025px) {
        top: -10.5rem;
        right: -45vw;
        width: 47.5vw;
      }
    }
  }

  .whatWeDoCards {
    @media (min-width: 1025px) {
      max-width: 1200px;
    }
  }
`

const WhatWeDo = () => {
  return (
    <WhatWeDoSection id="whatWeDoSec">
      <StandardWrapper>
        <div className="what-we-do-content">
          <MainTitle title="What we do" />
          <BodyCopy content="<p>We are a small digital agency with big curiosity and determination to lead through good times and tough ones. We’ll roll up our sleeves to guide you through the digital journey that every business owner is facing right now.</p><p>Do you want to create captivating experiences and showcase the new problems that you are solving for your customers? Better yet, do you want to level up your business while everyone is still reeling from the new reality of life? Heck yes!</p>" />
          <StyledScriptCallOut content="<p>Don’t waste any time or money doing the wrong things to get your message out. Have a plan and captivating story to tell. Let us help you.</p>" />
          <CompassDraw />
          <div className="what-headphones">
            <Headphones />
          </div>
        </div>
      </StandardWrapper>
      <AllCards />
    </WhatWeDoSection>
  )
}

export default WhatWeDo
