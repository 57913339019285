import React, { useEffect, useState, useRef } from "react"
import VisibilitySensor from "react-visibility-sensor"
import { TimelineMax, TweenMax, CSSPlugin, Power0, AttrPlugin } from "gsap/all"
import styled from "styled-components"

import { fonts, fontSizer } from "../../../../Utilities"
import StandardWrapper from "../../../Shared/StandardWrapper"
import Card from "./Card"
import EyeSvg from "../../../../assets/eye.svg"
import CirclesSvg from "../../../../assets/threecircles.svg"
import PencilRulerSvg from "../../../../assets/pencilruler.svg"

const plugins = [CSSPlugin, AttrPlugin]

const StyledCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: calc(50% - 1rem);
  max-width: 25rem;
  margin: 2rem 0.5rem;
  padding: 2rem 1rem;
  background: #fff;
  box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #c4c4c4;
  overflow: hidden;

  @media (min-width: 768px) {
    width: calc(33.33% - 4rem);
    max-width: 100%;
    margin: 2rem;
    padding: 2rem;
  }

  @media (min-width: 1025px) {
    padding: 5rem 2rem;
  }

  .card-icon {
    width: 100%;
    max-width: 7.5rem;
    margin: 2rem auto;
    overflow: visible;

    @media (min-width: 768px) {
      max-width: 10rem;
    }

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 2rem 0;
    }

    svg {
      width: 100%;
      max-width: 100%;
      overflow: visible;
    }
  }

  h3 {
    ${fontSizer(1.8, 3.5, 76.8, 110, 2.2)};
    width: 100%;
    color: #707070;
    font-family: ${fonts.fontTertiary};
    text-transform: uppercase;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 1.65px;
    text-align: center;
  }
`

const setBoxes = boxes => {
  TweenMax.set(boxes, { y: 200, autoAlpha: 0 })
}

const animateBoxes = boxes => {
  TweenMax.staggerTo(boxes, 1, { y: 0, autoAlpha: 1 }, 0.5)
}

const AllCards = () => {
  const [hasRanOnce, setHasRanOnce] = useState(false)
  const cardOne = useRef(null)
  const cardTwo = useRef(null)
  const cardThree = useRef(null)

  useEffect(() => {
    setBoxes([cardOne.current, cardTwo.current, cardThree.current])
  }, [])

  const onChange = isVisible => {
    if (isVisible && !hasRanOnce) {
      animateBoxes([cardOne.current, cardTwo.current, cardThree.current])
      setHasRanOnce(true)
    }
  }

  return (
    <VisibilitySensor onChange={onChange}>
      <StandardWrapper modifierClass="whatWeDoCards">
        <StyledCard ref={cardOne}>
          <div className="card-icon">
            <EyeSvg />
          </div>
          <h3>Stand Out</h3>
        </StyledCard>

        <StyledCard ref={cardTwo}>
          <div className="card-icon">
            <CirclesSvg id="threeCircles" />
          </div>
          <h3>Grow your brand</h3>
        </StyledCard>

        <StyledCard ref={cardThree}>
          <div className="card-icon">
            <PencilRulerSvg id="pencilRuler" />
          </div>
          <h3>Save Time</h3>
        </StyledCard>
      </StandardWrapper>
    </VisibilitySensor>
  )
}

export default AllCards
