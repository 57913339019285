import React, { Component } from "react"
import styled from "styled-components"
import MainTitle from "../../Shared/MainTitle"
import { fontSizer } from "../../../Utilities/"
import MountainOneSvg from "../../../assets/h-mountain1.svg"
import MountainThreeSvg from "../../../assets/h-mountain3.svg"
import MountainFourSvg from "../../../assets/h-mountain4.svg"
import TreeOneSvg from "../../../assets/h-tree1.svg"
import CloudOneSvg from "../../../assets/h-cloud1.svg"
import CloudTwoSvg from "../../../assets/h-cloud2.svg"
import CloudThreeSvg from "../../../assets/h-cloud3.svg"
import SunSvg from "../../../assets/h-sun.svg"
import WhiteCloudSvg from "../../../assets/cloud-white.svg"

import {
  TimelineMax,
  TweenMax,
  RoughEase,
  CSSPlugin,
  Power0,
  AttrPlugin,
  Elastic,
} from "gsap/all"
import ScrollMagic from "scrollmagic"
import animationGsap from "animation.gsap"
import debugAddIndicators from "debug.addIndicators"

// Need this for tree shaking.
const plugins = [CSSPlugin, AttrPlugin, animationGsap]

const HeaderSceneStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 55rem;
  max-height: 60rem;
  background: linear-gradient(
    168deg,
    #fff,
    #fcfdfd 7%,
    #f4f6f7 20%,
    #e6ebee 32%,
    #d2dbe0 42%,
    #b8c7cf 50%,
    #98aeba 60%,
    #7390a0 65%,
    #476e83 85%,
    #174862 90%,
    #003653
  );
  overflow: hidden;

  @media (min-width: 768px) {
    height: 100vh;
    min-height: 85rem;
    max-height: 90rem;
    background: linear-gradient(
      168deg,
      #fff,
      #fcfdfd 24%,
      #f4f6f7 37%,
      #e6ebee 49%,
      #d2dbe0 58%,
      #b8c7cf 67%,
      #98aeba 75%,
      #7390a0 83%,
      #476e83 90%,
      #174862 97%,
      #003653
    );
  }
`

const HeaderTitle = styled.div`
  position: absolute;
  bottom: 19rem;
  right: 0;
  left: 0;
  z-index: 100;
  text-align: center;

  @media (min-width: 370px) {
    bottom: 27.5rem;
  }

  @media (min-width: 768px) {
    bottom: 10vw;
    right: 53.5vw;
    left: auto;
  }

  @media (min-width: 1025px) {
    bottom: 14vw;
  }

  h2 {
    ${fontSizer(2.2, 4, 76.8, 160, 2.6)}
  }
`

const MountainOneStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  left: -12.5vw;
  width: 55vw;
  height: 100%;
  transform: scale(0);
  z-index: 3;

  @media (min-width: 1025px) {
    max-width: 85rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 17%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const MountainTwoStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  left: 10vw;
  width: 45vw;
  height: 100%;
  transform: scale(0);
  z-index: 2;

  @media (min-width: 1025px) {
    max-width: 70rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 17%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const MountainThreeStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  right: 15vw;
  width: 38.5vw;
  height: 100%;
  transform: scale(0);
  z-index: 5;

  @media (min-width: 1025px) {
    max-width: 60rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 17%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const MountainFourStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  right: 2vw;
  width: 32vw;
  height: 100%;
  transform: scale(0);
  z-index: 4;

  @media (min-width: 1025px) {
    max-width: 50rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 17%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const MountainFiveStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  right: 34vw;
  width: 37.5vw;
  height: 100%;
  transform: scale(0);
  z-index: 6;

  @media (min-width: 1025px) {
    max-width: 58rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 17%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const WhiteCloudWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  bottom: -4rem;
  left: 0rem;
  width: 115vw;
  z-index: 10;

  @media (min-width: 375px) {
    bottom: -5rem;
  }

  @media (min-width: 768px) {
    bottom: -10rem;
  }

  @media (min-width: 1025px) {
    bottom: -15rem;
    max-width: 200rem;
  }

  @media (min-width: 1850px) {
    bottom: -30rem;
    max-width: 275rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    bottom: -45rem;
  }

  .cloud-inside {
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      width: 100%;
      height: 100%;
      bottom: -45rem;
    }
  }

  svg {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
`

const SunWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 17vw;
  right: 42.5%;
  width: 17vw;
  height: 17vw;
  transform: translateY(125%);
  z-index: 1;

  @media (min-width: 1025px) {
    max-width: 27.5rem;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const CloudOneStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 5%;
  left: 0;
  width: 70vw;
  max-width: 97rem;
  height: 50vw;
  max-height: 70rem;
  z-index: 25;

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const CloudTwoStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 35%;
  right: 25%;
  width: 20vw;
  max-width: 28rem;
  height: 20vw;
  max-height: 28rem;
  z-index: 50;

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const CloudThreeStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 10%;
  right: 5%;
  width: 20vw;
  max-width: 28rem;
  height: 20vw;
  max-height: 28rem;
  z-index: 50;

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const TreeOneStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  right: 5%;
  width: 5vw;
  height: 100%;
  transform: scale(0);
  z-index: 7;

  @media (min-width: 1025px) {
    max-width: 8.1rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 43%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const TreeTwoStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  right: 15%;
  width: 7.5vw;
  height: 100%;
  transform: scale(0);
  z-index: 7;

  @media (min-width: 1025px) {
    max-width: 12.2rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 37%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const TreeThreeStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  right: 20.5%;
  width: 7vw;
  height: 100%;
  transform: scale(0);
  z-index: 7;

  @media (min-width: 1025px) {
    max-width: 11.2rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 34%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const moveTheClouds = () => {
  const cloudOne = document.getElementById("cloudOne")
  const cloudTwo = document.getElementById("cloudTwo")
  const cloudThree = document.getElementById("cloudThree")

  const cloudTimeline = new TimelineMax()
  const cloudOneMovement = new TimelineMax()
  const cloudTwoMovement = new TimelineMax()
  const cloudThreeMovement = new TimelineMax()

  const cloudOneRepeatMovement = () => {
    const randNum = Math.floor(Math.random() * 7.5) + 6.0

    cloudOneMovement
      .add("start")
      .fromTo(
        cloudOne,
        randNum,
        { x: "-400%", ease: Power0.easeNone },
        { x: "150%", ease: Power0.easeNone },
        "start"
      )
      .add("end")
      .add(function() {
        cloudOneRepeatMovement()
      }, "end")
  }

  const cloudTwoRepeatMovement = () => {
    const randNum = Math.floor(Math.random() * 4) + 3

    cloudTwoMovement
      .add("start")
      .fromTo(
        cloudTwo,
        randNum,
        { x: "-400%", ease: Power0.easeNone },
        { x: "250%", ease: Power0.easeNone },
        "start"
      )
      .add("end")
      .add(function() {
        cloudTwoRepeatMovement()
      }, "end")
  }

  const cloudThreeRepeatMovement = () => {
    const randNum = Math.floor(Math.random() * 4) + 3

    cloudThreeMovement
      .add("start")
      .fromTo(
        cloudThree,
        randNum,
        { x: "-400%", ease: Power0.easeNone },
        { x: "150%", ease: Power0.easeNone },
        "start"
      )
      .add("end")
      .add(function() {
        cloudThreeRepeatMovement()
      }, "end")
  }
  cloudTimeline
    .add("start")
    .fromTo(
      cloudOne,
      1,
      { x: "0%", ease: Power0.easeNone },
      { x: "150%", ease: Power0.easeNone },
      "start"
    )
    .add(function() {
      cloudOneRepeatMovement()
    })
    .fromTo(
      cloudTwo,
      1,
      { x: "0%", ease: Power0.easeNone },
      { x: "250%", ease: Power0.easeNone },
      "start"
    )
    .add(function() {
      cloudTwoRepeatMovement()
    })
    .fromTo(
      cloudThree,
      1,
      { x: "0%", ease: Power0.easeNone },
      { x: "150%", ease: Power0.easeNone },
      "start"
    )
    .add(function() {
      cloudThreeRepeatMovement()
    })
}

const moveTheBigCloud = () => {
  const controller = new ScrollMagic.Controller()
  const timeLine = new TimelineMax()
  const bigCloud = document.getElementById("bigCloud")
  const cloudOne = document.getElementById("cloudOne")
  const cloudTwo = document.getElementById("cloudTwo")
  const cloudThree = document.getElementById("cloudThree")
  const sun = document.getElementById("sun")
  const sceneTrigger = document.getElementById("mainHeaderScene")

  timeLine
    .add("start")
    .fromTo(
      bigCloud,
      1,
      { y: "0%", scale: 1, ease: Power0.easeNone },
      {
        y: "-5%",
        scale: 1.1,
        transformOrigin: "center bottom",
        ease: Power0.easeNone,
      },
      "start"
    )
    .fromTo(
      cloudOne,
      1,
      { x: "0%", y: "0%", ease: Power0.easeNone },
      {
        x: "-5%",
        y: "5%",
        transformOrigin: "center bottom",
        ease: Power0.easeNone,
      },
      "start"
    )
    .fromTo(
      cloudTwo,
      1,
      { x: "0%", y: "0%", ease: Power0.easeNone },
      {
        x: "15%",
        y: "40%",
        transformOrigin: "center bottom",
        ease: Power0.easeNone,
      },
      "start"
    )
    .fromTo(
      cloudThree,
      1,
      { x: "0%", y: "0%", ease: Power0.easeNone },
      {
        x: "20%",
        y: "10%",
        transformOrigin: "center bottom",
        ease: Power0.easeNone,
      },
      "start"
    )
    .fromTo(
      sun,
      1,
      { scale: 1, ease: Power0.easeNone },
      { scale: 1.5, ease: Power0.easeNone },
      "start"
    )

  new ScrollMagic.Scene({
    triggerElement: sceneTrigger,
    duration: "100%",
    reverse: true,
    offset: 0,
    triggerHook: 0,
  })
    .setTween(timeLine)
    .addTo(controller)
  // .addIndicators()
}

const growTheMountains = () => {
  const timeLine = new TimelineMax({ delay: 0.4 })
  const mountOne = document.getElementById("mountOne")
  const mountTwo = document.getElementById("mountTwo")
  const mountThree = document.getElementById("mountThree")
  const mountFour = document.getElementById("mountFour")
  const mountFive = document.getElementById("mountFive")

  const treeOne = document.getElementById("treeOne")
  const treeTwo = document.getElementById("treeTwo")
  const treeThree = document.getElementById("treeThree")

  const sun = document.getElementById("sun")

  const mainTitle = document.getElementById("mainTitle")
  const mainTitleTop = mainTitle.querySelector(".title")
  const mainTitleBottom = mainTitle.querySelector(".sub-title")

  timeLine
    .staggerFromTo(
      [mountOne, mountTwo, mountThree, mountFour, mountFive],
      0.3,
      {
        scale: 0,
        transformOrigin: "center bottom",
        ease: Elastic.easeOut.config(1, 0.8),
      },
      {
        scale: 1,
        transformOrigin: "center bottom",
        ease: Elastic.easeOut.config(1, 0.8),
      },
      0.05
    )
    .staggerFromTo(
      [treeOne, treeTwo, treeThree],
      0.3,
      {
        scale: 0,
        transformOrigin: "center bottom",
        ease: Elastic.easeOut.config(1, 0.8),
      },
      {
        scale: 1,
        transformOrigin: "center bottom",
        ease: Elastic.easeOut.config(1, 0.8),
      },
      0.05
    )
    .fromTo(sun, 1, { autoAlpha: 0, y: "125%" }, { autoAlpha: 1, y: "0%" })
    .staggerFromTo(
      [mainTitleTop, mainTitleBottom],
      2,
      { autoAlpha: 0, y: "50%" },
      { autoAlpha: 1, y: "0%" },
      1,
      "-=0.5"
    )
}

class HeaderScene extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    // moveTheClouds()
    moveTheBigCloud()
    growTheMountains()
  }
  componentDidUpdate() {
    // moveTheClouds()
  }
  render() {
    return (
      <HeaderSceneStyled id="mainHeaderScene">
        <HeaderTitle id="mainTitle">
          <MainTitle
            title="You know your vision."
            subTitle="We know storytelling."
          />
        </HeaderTitle>
        <div>
          <CloudOneStyles id="cloudOne">
            <CloudThreeSvg />
          </CloudOneStyles>
          <CloudTwoStyles id="cloudTwo">
            <CloudOneSvg />
          </CloudTwoStyles>
          <CloudThreeStyles id="cloudThree">
            <CloudTwoSvg />
          </CloudThreeStyles>
        </div>

        <div>
          <MountainOneStyled id="mountOne">
            <MountainOneSvg />
          </MountainOneStyled>

          <MountainTwoStyled id="mountTwo">
            <MountainFourSvg />
          </MountainTwoStyled>

          <MountainFiveStyled id="mountThree">
            <MountainOneSvg />
          </MountainFiveStyled>

          <MountainThreeStyled id="mountFour">
            <MountainThreeSvg />
          </MountainThreeStyled>

          <MountainFourStyled id="mountFive">
            <MountainFourSvg />
          </MountainFourStyled>
        </div>

        <div>
          <TreeOneStyles id="treeOne">
            <TreeOneSvg />
          </TreeOneStyles>
          <TreeTwoStyles id="treeTwo">
            <TreeOneSvg />
          </TreeTwoStyles>
          <TreeThreeStyles id="treeThree">
            <TreeOneSvg />
          </TreeThreeStyles>
        </div>

        <div>
          <SunWrapper id="sun">
            <SunSvg />
          </SunWrapper>
        </div>
        <div>
          <WhiteCloudWrapper>
            <div id="bigCloud" className="cloud-inside">
              <WhiteCloudSvg />
            </div>
          </WhiteCloudWrapper>
        </div>
      </HeaderSceneStyled>
    )
  }
}

export default HeaderScene
