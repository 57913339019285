import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import MainNavSubMenu from "./MainNavSubMenu"
import { colors, fonts } from "../../../Utilities"

const MainNavItemStyled = styled.li`
  position: relative;
  margin: 0 1rem;
  font-size: 1.4rem;
  line-height: 1.19;

  a {
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
    background: ${props =>
      props.isCurrent ? colors.colorSecondary : "transparent"};
    color: ${props => (props.isCurrent ? colors.white : "#182a3e")};
    font-family: ${fonts.fontPrimary};
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.19;

    @media (min-width: ${props => props.theme.bpDesksm}) {
    }

    &:hover {
      background: ${props =>
        props.isCurrent
          ? props.theme.colorPrim
          : props => props.theme.colorSec};
    }
  }

  &:hover {
    .main-nav-sub {
      visibility: visible;
      opacity: 1;
    }
  }
`

const MainNavItem = ({ object_slug, title, location, wordpress_children }) => {
  const currentPageSlug = object_slug === "home" ? "/" : `/${object_slug}`
  const isCurrent =
    currentPageSlug === location
      ? true
      : `${currentPageSlug}/` === location
      ? true
      : false

  return (
    <MainNavItemStyled isCurrent={isCurrent}>
      <Link
        className="main-nav-item"
        to={object_slug === "home" ? "/" : `/${object_slug}`}
      >
        {title}
      </Link>
      {wordpress_children !== null && wordpress_children && (
        <MainNavSubMenu
          subItems={wordpress_children}
          currentPageSlug={currentPageSlug}
          location={location}
        />
      )}
    </MainNavItemStyled>
  )
}

export default MainNavItem
