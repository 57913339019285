import React from "react"
import styled from "styled-components"

import { bodyCopy } from "../../Utilities/"

const StyledBodyCopy = styled.div`
  p {
    ${bodyCopy};
    color: #585858;

    .body-uppercase {
      text-transform: uppercase;
    }
  }
`

const BodyCopy = ({ content }) => {
  return <StyledBodyCopy dangerouslySetInnerHTML={{ __html: content }} />
}

export default BodyCopy
