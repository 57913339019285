import React from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"

import { fonts, fontSizer } from "../../../../Utilities"

const StyledCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: calc(50% - 1rem);
  max-width: 25rem;
  margin: 2rem 0.5rem;
  padding: 2rem 1rem;
  background: #fff;
  box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #c4c4c4;
  overflow: hidden;

  @media (min-width: 768px) {
    width: calc(33.33% - 4rem);
    max-width: 100%;
    margin: 2rem;
    padding: 2rem;
  }

  @media (min-width: 1025px) {
    padding: 5rem 2rem;
  }

  .card-icon {
    width: 100%;
    max-width: 7.5rem;
    margin: 2rem auto;
    overflow: visible;

    @media (min-width: 768px) {
      max-width: 10rem;
    }

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 2rem 0;
    }

    svg {
      width: 100%;
      max-width: 100%;
      overflow: visible;
    }
  }

  h3 {
    ${fontSizer(1.8, 3.5, 76.8, 110, 2.2)};
    width: 100%;
    color: #707070;
    font-family: ${fonts.fontTertiary};
    text-transform: uppercase;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 1.65px;
    text-align: center;
  }
`

const Card = ({ title, children, isActive }) => {
  const props = useSpring({
    opacity: isActive ? 1 : 0,
  })
  return (
    <StyledCard>
      <div className="card-icon">{children}</div>
      <h3>{title}</h3>
    </StyledCard>
  )
}

export default Card
