import React, { useEffect, useState, useRef } from "react"
import VisibilitySensor from "react-visibility-sensor"
import { TimelineMax, TweenMax, CSSPlugin, Power0, AttrPlugin } from "gsap/all"

import CompassSvg from "../../../assets/compass.svg"

const plugins = [CSSPlugin, AttrPlugin]

const drawTheCompass = () => {
  const compass = document.getElementById("compassSvg")
  const pathOne = compass.querySelector("#comOne")
  const pathTwo = compass.querySelector("#comTwo")
  const pathThree = compass.querySelector("#comThree")
  const pathFour = compass.querySelector("#comFour")
  const pathFive = compass.querySelector("#comFive")
  const pathSix = compass.querySelector("#comSix")
  const compassCircle = compass.querySelector("#comCircle")

  TweenMax.set(pathOne, {
    strokeDasharray: 592.78466796875,
    strokeDashoffset: 592.78466796875,
  })
  TweenMax.set(pathTwo, {
    strokeDasharray: 202.1647186279297,
    strokeDashoffset: 202.1647186279297,
  })
  TweenMax.set(pathThree, {
    strokeDasharray: 204.09332275390625,
    strokeDashoffset: 204.09332275390625,
  })
  TweenMax.set(pathFour, {
    strokeDasharray: 204.91940307617188,
    strokeDashoffset: 204.91940307617188,
  })
  TweenMax.set(pathFive, { strokeDasharray: 0, strokeDashoffset: 0 })
  TweenMax.set(pathSix, {
    strokeDasharray: 72.362060546875,
    strokeDashoffset: 72.362060546875,
  })
}

const playTheCompass = () => {
  const timeLine = new TimelineMax()
  const compass = document.getElementById("compassSvg")
  const pathOne = compass.querySelector("#comOne")
  const pathTwo = compass.querySelector("#comTwo")
  const pathThree = compass.querySelector("#comThree")
  const pathFour = compass.querySelector("#comFour")
  const pathFive = compass.querySelector("#comFive")
  const pathSix = compass.querySelector("#comSix")
  const compassCircle = compass.querySelector("#comCircle")

  timeLine
    .add("start")
    .fromTo(
      pathOne,
      1,
      { strokeDashoffset: 592.78466796875, ease: Power0.easeNone },
      { strokeDashoffset: 0, ease: Power0.easeNone },
      "start"
    )
    .fromTo(
      pathTwo,
      0.5,
      { strokeDashoffset: 202.1647186279297, ease: Power0.easeNone },
      { strokeDashoffset: 0, ease: Power0.easeNone },
      "start+=0.5"
    )
    .fromTo(
      pathThree,
      0.5,
      { strokeDashoffset: 204.09332275390625, ease: Power0.easeNone },
      { strokeDashoffset: 0, ease: Power0.easeNone },
      "start+=0.5"
    )
    .fromTo(
      pathFour,
      0.75,
      { strokeDashoffset: 204.91940307617188, ease: Power0.easeNone },
      { strokeDashoffset: 0, ease: Power0.easeNone },
      "start+=0.25"
    )
    .fromTo(
      pathFive,
      0.75,
      { strokeDashoffset: 0, ease: Power0.easeNone },
      { strokeDashoffset: 0, ease: Power0.easeNone },
      "start+=0.25"
    )
    .fromTo(
      pathSix,
      0.25,
      { strokeDashoffset: 72.362060546875, ease: Power0.easeNone },
      { strokeDashoffset: 0, ease: Power0.easeNone },
      "start+=0.75"
    )
    .fromTo(compassCircle, 0.25, { autoAlpha: 0 }, { autoAlpha: 1 })
}

const CompassDraw = () => {
  useEffect(() => {
    drawTheCompass()
  }, [])

  const [hasRanOnce, setHasRanOnce] = useState(false)

  const onChange = isVisible => {
    if (isVisible && !hasRanOnce) {
      console.log("PLAYED!!!")
      playTheCompass()
      setHasRanOnce(true)
    }
  }
  return (
    <>
      <VisibilitySensor onChange={onChange}>
        <div id="compassSvg" className="what-compass-svg">
          <CompassSvg />
        </div>
      </VisibilitySensor>
    </>
  )
}

export default CompassDraw
