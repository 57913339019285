import React from "react"
import styled from "styled-components"

import { headlineOne } from "../../Utilities/"

const TitleUnderlineStyled = styled.div`
  width: 100%;

  h2 {
    ${headlineOne(`#000`)};
    display: inline-block;
    position: relative;
    text-transform: uppercase;

    &::after {
      position: absolute;
      right: 0;
      bottom: -1rem;
      left: 0;
      height: 1rem;
      margin: auto;
      background: #ffb718;
      content: "";
    }
  }
`

const TitleUnderline = ({ title }) => {
  return (
    <TitleUnderlineStyled>
      <h2>{title}</h2>
    </TitleUnderlineStyled>
  )
}

export default TitleUnderline
