import React from "react"
import styled from "styled-components"

import SocialFollow from "../../Shared/SocialFollow"

const MobileFooterStyled = styled.div`
  width: 100%;
  margin-top: 5rem;
  padding: 2rem;
  text-align: center;

  .wrapper {
    justify-content: center;
  }
`

const MobileFooter = () => {
  return (
    <MobileFooterStyled>
      <SocialFollow />
    </MobileFooterStyled>
  )
}

export default MobileFooter
