import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import TitleUnderline from "../../Shared/TitleUnderline"

import StyledPortfolioOne from "./RecentWorkParts/FirstProfolio"
import PortfolioTwo from "./RecentWorkParts/SecondPortfolio"
import PortfolioThree from "./RecentWorkParts/ThirdPortfolio"

import { headlineThree, buttonOne } from "../../../Utilities/"

const RecentWorkSection = styled.section`
  position: relative;
  z-index: 25;

  .class-container,
  .recent-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .class-container {
    background: #fff;
  }

  .recent-title {
    width: 100%;
    margin-bottom: 5rem;
    text-align: center;

    @media (min-width: 768px) {
      margin-bottom: 2.5rem;
    }
  }

  .recent-portone {
    width: 100%;
    margin-bottom: 0.5rem;
    background: #fff;

    @media (min-width: 768px) {
      width: calc(60% - 0.5rem);
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
  }

  .recent-porttwothree {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    background: #fff;

    @media (min-width: 768px) {
      width: calc(40%);
    }
  }

  .recent-porttwo {
    width: 100%;
    margin-bottom: 0.25rem;
  }

  .recent-portthree {
    width: 100%;
    margin-top: 0.25rem;
  }

  .recent-button {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 5rem 2rem;

    p {
      ${headlineThree}
      margin: 0;
      padding: 0;
      color: #707070;
      text-transform: uppercase;

      @media (min-width: 768px) {
        margin-right: 2rem;
      }
    }

    a {
      ${buttonOne};

      @media (min-width: 768px) {
        margin-left: 2rem;
      }
    }
  }
`

const RecentWork = () => {
  return (
    <RecentWorkSection>
      <div className="recent-wrapper">
        <div className="recent-title">
          <TitleUnderline title="recent work" />
        </div>
        <div className="class-container">
          <div className="recent-portone">
            <StyledPortfolioOne />
          </div>
          <div className="recent-porttwothree">
            <div className="recent-porttwo">
              <PortfolioTwo />
            </div>
            <div className="recent-portthree">
              <PortfolioThree />
            </div>
          </div>
        </div>
        <div className="recent-button">
          <p>Tell us what you need?</p>
          <Link to="/contact">Start a Project</Link>
        </div>
      </div>
    </RecentWorkSection>
  )
}

export default RecentWork
