import React, { Component } from "react"
import styled from "styled-components"
import StandardWrapper from "../../Shared/StandardWrapper"
import ScriptTitle from "../../Shared/ScriptTitle"
import BodyCopy from "../../Shared/BodyCopy"
import FlameSvg from "../../../assets/flames.svg"

// GSAP and ScrollMagic Package. //
import {
  TimelineMax,
  TweenMax,
  RoughEase,
  CSSPlugin,
  Power0,
  AttrPlugin,
  Elastic,
} from "gsap/all"
import ScrollMagic from "scrollmagic"
import animationGsap from "animation.gsap"
import debugAddIndicators from "debug.addIndicators"

// Need this for tree shaking. //
const plugins = [CSSPlugin, AttrPlugin, animationGsap]

const IntroSection = styled.section`
  .introWrapper {
    align-items: center;
    flex-direction: row-reverse;
  }
`

const IntroContent = styled.div`
  align-self: center;
  width: 100%;
  text-align: center;

  @media (min-width: 768px) {
    width: calc(65%);
    text-align: left;
  }
`

const IntroFire = styled.div`
  display: none;
  align-self: center;
  width: 100%;
  max-width: 10rem;
  margin: 2rem auto 0;

  @media (min-width: 768px) {
    display: block;
    width: calc(35%);
    max-width: 15.5rem;
    margin: auto;
  }

  svg {
    width: 100%;
    max-width: 100%;

    ${"" /* #flameOne {
      stroke-dasharray: 100;
    } */}
  }
`

const drawTheFlame = () => {
  const flame = document.getElementById("flame")
  const flameLineOne = flame.querySelector("#flameOne")
  const flameLineTwo = flame.querySelector("#flameTwo")
  const sceneTrigger = document.getElementById("flameTrigger")
  // var l = flameLineOne.getTotalLength()
  // var l2 = flameLineTwo.getTotalLength()
  const controller = new ScrollMagic.Controller()
  const timeLine = new TimelineMax()

  TweenMax.set(flameLineOne, { strokeDasharray: 291.94525146484375 })
  TweenMax.set(flameLineTwo, { strokeDasharray: 794.6396484375 })
  timeLine
    .fromTo(
      flameLineTwo,
      0.5,
      { strokeDashoffset: 794.6396484375, ease: Power0.easeNone },
      { strokeDashoffset: 0, ease: Power0.easeNone }
    )
    .fromTo(
      flameLineOne,
      0.5,
      { strokeDashoffset: 291.94525146484375, ease: Power0.easeNone },
      { strokeDashoffset: 0, ease: Power0.easeNone }
    )

  new ScrollMagic.Scene({
    triggerElement: sceneTrigger,
    duration: 0,
    reverse: false,
    offset: 0,
    triggerHook: 0.65,
  })
    .setTween(timeLine)
    .addTo(controller)
  // .addIndicators()
}

class Intro extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    drawTheFlame()
  }

  render() {
    return (
      <IntroSection id="flameTrigger">
        <StandardWrapper modifierClass="introWrapper">
          <IntroContent>
            <ScriptTitle title="<h2 class='title-script'>Are you ready to take your company to the next chapter; ready to springboard your business to a new evolution of your brand?</h2>" />
            <BodyCopy content="<p>As a busy leader navigating your team, you don’t have time to waste. You need to turn your big vision into great marketing.</p><p>Switchback Creative will guide you, bringing your vision to life by telling the story of your brand. Your digital presence, more important now than ever, will create profitable, loyal customers that are seeking confident services and products in a time of chaos.</p>" />
          </IntroContent>
          <IntroFire id="flame">
            <FlameSvg />
          </IntroFire>
        </StandardWrapper>
      </IntroSection>
    )
  }
}

export default Intro
