import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { standardWrapper } from "../../Utilities/"
import MainNav from "./MainNav/MainNav"
import MobileMenu from "./MobileMenu/MobileMenu"
import SwitchbackLogo from "../../Elements/Images/Logos/SwitchbackLogo"

const MainHeaderStyled = styled.header`
  position: ${props => (props.floatingNav ? "absolute" : "relative")};
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
`

const MainHeaderWrapper = styled.div`
  ${standardWrapper}
`

const MainHeaderLogo = styled.div`
  width: 100%;
  margin: 2rem auto;

  @media (min-width: ${props => props.theme.bpDesksm}) {
    width: calc(20%);
  }

  h1 {
    position: absolute;
    top: -200%;
    left: -200%;
    visibility: hidden;
    font-size: 0rem;
  }

  .gatsby-image-wrapper {
    max-width: 25rem;
    margin: auto;
  }

  img {
    width: 100%;
  }
`

const Header = ({ siteTitle, location }) => {
  const floatingNav =
    location === "/" ||
    location === "/about" ||
    location === "/about/" ||
    location === "/services" ||
    location === "/services/" ||
    location === "/portfolio" ||
    location === "/portfolio/" ||
    location === "/blog" ||
    location === "/blog/" ||
    location === "/contact" ||
    location === "/contact/" ||
    location === "/trailblazer-fund" ||
    location === "/trailblazer-fund/" ||
    location === "/podcast" ||
    location === "/podcast/" ||
    location === "/about/in-the-community" ||
    location === "/about/in-the-community/"
      ? true
      : false

  return (
    <MainHeaderStyled floatingNav={floatingNav}>
      <MainHeaderWrapper>
        <MainHeaderLogo>
          <h1>{siteTitle}</h1>
          <Link to="/">
            <SwitchbackLogo />
          </Link>
        </MainHeaderLogo>
        <MainNav location={location} />
        <MobileMenu location={location} />
      </MainHeaderWrapper>
    </MainHeaderStyled>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
