import React from "react"
import styled from "styled-components"

import { standardWrapper } from "../../Utilities/"

const StandardWrapperStyled = styled.div`
  ${standardWrapper}
`

const StandardWrapper = ({ children, modifierClass }) => {
  return (
    <StandardWrapperStyled className={modifierClass && modifierClass}>
      {children}
    </StandardWrapperStyled>
  )
}

export default StandardWrapper
